@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?x3qbzz');
  src:  url('fonts/icomoon.eot?x3qbzz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?x3qbzz') format('truetype'),
    url('fonts/icomoon.woff?x3qbzz') format('woff'),
    url('fonts/icomoon.svg?x3qbzz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-2:before {
  content: "\e907";
  color: #2e59ba;
}
.icon-21 .path1:before {
  content: "\e908";
  color: rgb(251, 188, 5);
}
.icon-21 .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-21 .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-21 .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(66, 133, 244);
}
.icon-info_black_24dp-1:before {
  content: "\e90d";
  color: #6bd6f0;
}
.icon-magnifyingglass:before {
  content: "\e906";
  color: #040b5d;
}
.icon-1:before {
  content: "\e900";
  color: #e2e2e2;
}
.icon-Page-1:before {
  content: "\e901";
  color: #e2e2e2;
}
.icon-Group:before {
  content: "\e902";
  color: #e2e2e2;
}
.icon-Vector-1:before {
  content: "\e903";
  color: #e2e2e2;
}
.icon-facebook-5-1:before {
  content: "\e904";
  color: #e2e2e2;
}
.icon-twitter-2-1:before {
  content: "\e905";
  color: #e2e2e2;
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
