.home_banner {
  margin-top: -7.5rem;
  background-image: linear-gradient(270deg, #6D1878 1.72%, #020659 49.22%, #0B67A0 100%),
    linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0.595833) 40.42%, rgba(196, 196, 196, 0) 100%);
  position: relative;
  .bottom_linear {
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
    height: 120px;
    background-image: linear-gradient(360deg, rgba(2, 6, 89, 0.2) 0%, rgba(2, 6, 89, 0) 100%);
    backdrop-filter: blur(25px);
  }
  .banner_inner {
    padding-top: 11.125rem;
    padding-bottom: 7.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .banner_texts {
      .title {
        font-weight: 600;
        font-size: 70px;
        line-height: 84px;
        letter-spacing: 0.09em;
        font-family: "Smooch Sans";
        text-transform: uppercase;
        .highlighted {
          color: var(--color-secondary);
          margin-left: 1.25rem;
        }
      }
      .description {
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        margin: 3.125rem 0;
      }

    }

    .banner_image {
      img {
        width: 486px;
      }
    }
    .btn_mobile {
      display: none;
    }
    @media screen and(max-width: 1200px) {
      .banner_image {
        img {
          width: 382px;
        }
      }
    }
    @media screen and(max-width: 1024px) {
      padding-top: 9.375rem;
      padding-bottom: 3.125rem;
      .banner_texts {
        .title {
          font-size: 50px;
          line-height: 60px;
        }
        .description {
          font-size: 25px;
          margin-top: 1.875rem;
        }
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      .banner_image {
        img {
          width: 304px;
        }
      }
      .banner_texts {
        text-align: center;
        .title {
          font-size: 35px;
          line-height: 40px;
        }
        .description {
          font-size: 20px;
          line-height: 20px;
          margin-top: 0.625rem;
          margin-bottom: 1.875rem;
        }
        .btn_desktop {
          display: none;
        }

      }
      .btn_mobile {
        display: block;
        text-align: center;
        margin-top: 3.125rem;
        button {
          width: 280px;
        }
      }
    }
    @media screen and (max-width: 480px) {
      padding-top: 6.25rem;
      .banner_image {
        img {
          width: 204px;
        }
      }
      .banner_texts {
        text-align: center;
        .title {
          font-size: 29px;
          line-height: 35px;
          letter-spacing: 0.08em;
          .highlighted {
            margin-left: 0.6rem;
          }
        }

        .description {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .btn_mobile {
        font-size: 14px;
        line-height: 13px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .bottom_linear {
      display: none;
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: -4rem;
  }

}
