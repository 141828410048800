.staking_banner {
  .banner_inner {
    .banner_texts {
      .btns {
        display: flex;
        flex-direction: row;
        column-gap: 50px;
      }
    }
    .banner_image {
      position: absolute;
      right: 0;
      top: 13.75rem;
    }
  }
}