
:root {
  --color-primary: #E2E2E2;
  --color-secondary: #6BD6F0;
  --blue: #020659;
  --blue-light: rgba(107, 214, 240, 0.2);
  --blue-dark: #040852;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
}
