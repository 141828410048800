footer {
  padding-top: 3.125rem !important;
  margin-top: 12.5rem;
  background-color: #040852;
  .footer_content {
    padding-bottom: 3.125rem;
  }
  .contact_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .contacts {
      text-align: end;
      font-size: 18px;
      line-height: 17px;
        p:not(:last-of-type) {
          margin-bottom: 20px;
        }
      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 11px;
      }
    }
    .logo {
      img {
        width: 192px;
        height: 192px;
        @media screen and (max-width: 1024px) {
          width: 120px;
          height: 120px;

        }
        @media screen and (max-width: 768px) {
          width: 100px;
          height: 100px;

        }
      }
    }
    @media screen and(max-width: 1024px) {
      align-items: center;
    }
  }
  .social_icons {
    display: flex;
    align-items: center;
    margin-bottom: 3.125rem;
    .nth_icon {
      position: relative;
      transition: 0.4s;
      cursor: pointer;
      background-color: inherit;
      .icon_back {
        position: absolute;
        width: 61px;
        height: 61px;
        top: -15px;
        left: -15px;
        background: inherit;
        filter: blur(25px);
        pointer-events: none;
        transition: 0.3s;
      }
      &:hover .icon_back  {
        background: rgba(107, 214, 240, 0.5);
      }

    }

    span {
      font-size: 24px;
    }
    .nth_icon:not(:last-of-type) {
      margin-right: 20px;
    }

    @media screen and (max-width: 1024px) {
      justify-content: center;
      margin-top: 6.25rem;
      margin-bottom: 0;
      span {
        font-size: 35px;
      }
      .nth_icon {
        &:not(:last-of-type) {
          margin-right: 50px;
        }
        .icon_back {
          left: -8px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 3.125rem;
      span {
        font-size: 20px;
      }
      .nth_icon {
        &:not(:last-of-type) {
          margin-right: 30px;
        }
        .icon_back {
          left: -15px;
        }
      }
    }
    @media screen and (max-width: 480px) {
      justify-content: space-between;
      .nth_icon:not(:last-of-type) {
        margin-right: unset;
      }
    }
  }
  .bottom_block {
    height: 6.25rem;
    border-top:  0.3px solid rgba(163, 163, 163, 0.5);;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
    color: #A3A3A3;

    .line {
      width: 40px;
      border: 0.3px solid rgba(163, 163, 163, 0.5);
      transform: rotate(90deg);
    }
    @media screen and (max-width: 1024px) {
      height: 80px;
      font-size: 20px;
    }
    @media screen and (max-width: 480px) {
      height: 50px;
      font-size: 10px;
      line-height: 10px;
      .line {
        width: 20px;
      }
    }
  }
  @media screen and (max-width: 768px){
    padding-top: 1.875rem !important;

  }
  @media screen and(max-width: 480px) {
    margin-top: 6.25rem;
  }
}
