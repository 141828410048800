@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/Gotham-Font/GothamBook.ttf");
}

@font-face {
  font-family: "Smooch Sans";
  src: url("../assets/fonts/Smooch-Font/SmoochSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Font/Roboto-Thin.ttf");
}

//Reset Styles
/* Preferred box-sizing value */

* {
  &:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
  }

  &::before, &::after {
    box-sizing: border-box;
  }
}

/* Reapply the pointer cursor for anchor tags */
a, button {
  cursor: pointer;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
//img {
//  max-width: 100%;
//}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}


/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Gotham;
}

//App

.App {
  background-color: #020659;
  color: #E2E2E2;
}

.padding_global {
  padding: 0 7.5rem;
  @media screen and (max-width: 1200px) {
    padding: 0 3.1875rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 1.875rem;
  }
  @media screen and (max-width: 320px) {
    padding: 0 1.25rem;
  }
}

.pt_page {
  padding-top: 10rem;
}
.pt_100 {
  padding-top: 6.25rem;
  @media screen and(max-width: 1024px) {
    padding-top: 3.125rem;
  }
}

.pt_50 {
  padding-top: 3.125rem;
  @media screen and(max-width: 1024px) {
    padding-top: 4.688rem;
  }
  @media screen and(max-width: 480px) {
    padding-top: 1.563rem;
  }

}

.btn_global {
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  color: #08488A;
  background-color: var(--color-secondary);
  border: 2px solid var(--color-secondary);
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background-color: var(--color-primary);
    font-weight: bold;
  }

  &.outlined {
    color: var(--color-secondary);
    background-color: unset;
    border: 2px solid var(--color-secondary);
    &:hover {
      background-color: var(--color-primary);
      color: #08488A;
    }
  }

  @media screen and(max-width: 480px) {
    font-size: 14px;
    line-height: 13px;
  }
}

.mt_block {
  margin-top: 6.25rem;
  @media screen and(max-width: 480px) {
    margin-top: 3.125rem;
  }
}

.title_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.125rem;

  .line_title {
    display: flex;
    align-items: center;
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 25px;
    line-height: 24px;

    .line {
      width: 50px;
      border-bottom: 1px solid;
      margin-right: 10px;
    }

    @media screen and(max-width: 480px) {
      font-size: 18px;
      line-height: 17px;
      .line {
        width: 30px;
      }
    }
  }

  .see_all {
    font-weight: var(--font-weight-medium);
    font-size: 18px;
    line-height: 17px;
    @media screen and (max-width: 480px) {
      font-weight: var(--font-weight-normal);
      font-size: 12px;
      line-height: 11px;
    }
  }

  @media screen and(max-width: 480px) {
    margin-bottom: 1.875rem;
  }
}


.title {
  font-weight: var(--font-weight-medium);
  font-size: 25px;
  line-height: 24px;

  @media screen and (max-width: 320px) {
    font-size: 18px;
    line-height: 17px;
  }
}

.bg_filter {
  background: rgba(107, 214, 240, 0.2);
  backdrop-filter: blur(50px);
  border-radius: 5px;
}
.bg_opacity {
  position: absolute;
  background: rgba(107, 214, 240, 0.5);
  filter: blur(150px);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: -1;
}

.relative_box {
  position: relative;
}

.fs_14 {
  font-size: 14px;
  line-height: 13px;
  @media screen and(max-width: 480px) {
    font-size: 12px;
    line-height: 11px;
  }
}
.fs_15 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  @media screen and(max-width: 480px) {
    font-size: 12px;
    line-height: 15px;
  }
}


.fs_18 {
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  @media screen and(max-width: 480px) {
    font-size: 14px;
    line-height: 13px;
  }
}

