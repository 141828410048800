header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.625rem !important;
  font-size: 18px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3.125rem;
  }

  .button {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    color: var(--color-secondary);
    border: 2px solid;
    border-radius: 5px;
  }

  img {
    width: 100px;
    object-fit: contain;
  }
  &.fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(30px);
    animation: fixedTop .4s;
    -webkit-animation: fixedTop .4s;
  }
  @media screen and (max-width: 1024px)  {
  }
  @media screen and (max-width: 480px)  {
    img {
      width: 45px;
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.menu_drawer {
  padding: 145px 117px 100px;
  height: 100vh;
  background-color: #000050;
  color: var(--color-primary);
  .close_icon {
    position: absolute;
    top: 56px;
    right: 41px;
  }
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
}

// Sticky Content
@keyframes fixedTop {
  0% {
    margin-top: -60px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes fixedBottom {
  0% {
    margin-top: -60px;
  }
  100% {
    margin-top: 0;
  }
}

